@import url('../../configs/main.css');

.saacontainer {
	position: fixed;
	z-index: 200;
	/*background-color: white;*/
	width: 50px;
	right: 62px;
	/*left: 0;*/
	margin: 0 auto;
	top: 7px;
	display: flex;
	float: right;
	height: 45px;
	z-index: 4;
	/*border-bottom-left-radius: 10px;*/
	/*border-bottom-right-radius: 10px;*/
	/*box-shadow: 0px 0px 7px 1px #101010;*/
}

.temperature {
	color: var(--dark-color);
	position: relative;
	left: 28px;
	top: 14px;
	font-weight: bold;
	font-size: 15px;
	float: right;
}

.imagee {
	color: var(--dark-color);
	position: absolute;
	top: 11px;
	left: 0px;
	opacity: 0.8;
}

.image2 {
	position: absolute;
	color: var(--dark-color);
	top: 9px;
	left: -5px;
	/*left: 1px;*/
	width: 35px;
	opacity: 0.8;

}

.celc {
	position: absolute;
	margin: 0;
	margin-top: -19px;
	/*float: right;*/
	font-size: 15px;
	right: -5px;
}

.windms {
	font-family: Arial;
	margin: 0;
	margin-top: 7px;
	float: right;
	font-size: 9px;
	padding-left: -16px;
	/*letter-spacing: initial;*/
}

.city {
	position: absolute;
	font-size: 10px;
	font-weight: 700;
	top: 26px;
	left: 15px;
}