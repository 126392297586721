@import url("../../configs/main.css");

.vikaheader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: var(--secondary-color);
  color: var(--dark-color);
  margin-bottom: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

/* .myform{
  position: 'absolute';
  top: 150px;
} */

span {
  margin-left: -5px;
}
.mapboxgl-popup-content {
  /*margin-top:  20px;*/
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0px 0px 5px 0.2px grey;
}

.mapboxgl-ctrl-icon {
  margin: 0px 0px 0px 0px;
}

.mapboxgl-popup {
  margin-top: 20px;
}

.formbtnvika {
  background-color: var(--secondary-color);
  color: var(--dark-color);
  font-size: 15px;
  border: none;
}
