.mapboxgl-ctrl-icon {
  margin: 0px 0px 0px 0px;
}

.tapahtuma_addbtn {
  position: absolute;
  z-index: 0;
  right: 10px;
  top: 70px;
}

.tapahtuma_addicon {
  color: var(--dark-color);
  background-color: var(--main-color);
  box-shadow: 0px 0px 5px 0.5px var(--dark-color);
  border-radius: 50px;
  cursor: pointer;
}

.tapahtumapopup {
  width: 90%;
  height: 30%;
  margin-top: 45%;
  position: absolute;
  background-color: white;
  box-shadow: 0px 0px 3px 1px grey;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  max-width: 400px;
  min-height: 180px;
  max-height: 200px;
}

.tapahtumapopupheader {
  text-align: center;
  font-size: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 700;
  background-color: #b1cfda;
}

.tapahtumapopuptext {
  padding: 20px;
  font-size: 15px;
  text-align: center;
}

.tapahtuma_closepopupbtn {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.tapahtuma_luotilibtn {
  position: absolute;
  left: 25%;
  bottom: 3px;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
}
.tapahtuma_kirjaudubtn {
  position: absolute;
  cursor: pointer;
  right: 25%;
  bottom: 3px;
  font-weight: bold;
  font-size: 13px;
  color: blue;
}
