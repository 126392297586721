@import url("../../configs/main.css");

.navbar {
  background-color: var(--main-color);
  height: 60px;
  box-shadow: 0px 0px 5px 1px #101010;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  z-index: 4;
}

.menu-bars {
  margin-left: 0.1rem;
  margin-top: -0.3rem;
  font-size: 1.8rem;
  background: none;
  color: var(--dark-color);
}

.nav-menu {
  background-color: var(--main-color);
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 56px;
  padding-top: 70px;
  left: -100%;
  transition: 850ms;
  z-index: 5;
  overflow-y: auto;
  box-shadow: 0px 8px 5px 1px var(--dark-color);
}
.nav-menu::-webkit-scrollbar {
  display: none;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 43px;
  font-weight: 600;
  z-index: 1;
}

.nav-text a {
  text-decoration: none;
  /*margin-left: 20px;*/
  color: var(--dark-color);
  font-size: 15px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-radius: 4px;
  font-weight: 600;
}

.nav-text a:hover {
  color: var(--dark-color);
  text-decoration: none;
}

.nav-menu-items {
  width: 120%;
  margin-left: -50px;
  margin-top: -50px;
}

.navbar-toggle {
  background-color: var(--dark-color);
  opacity: 0.8;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  padding-left: 16px;
}

.external-link {
  margin-top: 15px;
  color: var(--dark-color);
  font-size: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 31px;
  padding-bottom: 20px;
}

.external-link a {
  font-weight: 600;
  padding-left: 20px;
  color: var(--dark-color);
  text-decoration: none;
}

.external-link a:hover {
  color: var(--dark-color);
  text-decoration: none;
}

.mainlogo {
  background-image: url("../../assets/pictures/etusivulogo.png");
}
