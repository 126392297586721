@import url('../../configs/main.css');

.map-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.mapboxgl-ctrl-icon {
  margin: 0px 0px 0px 0px;
}

.mapboxgl-ctrl-group{
  position: relative;
  top: 90px;
  left: 10px;
  z-index: 1;
}

.kohdecard {
  background-color: var(--kohdekortin-tausta);
}

.kohdecard-selected{
  background-color: var(--kohdekortin-tausta-selected);
}

.kohdettekstit{
  color: var(--kohdekortin-tekstit);
}

.formbtnn{
  background-color: var(--secondary-color);
  color: var(--dark-color);
  border: none;
}