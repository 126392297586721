@import url('../../configs/main.css');

.rightvalikko::-webkit-scrollbar {
  display: none;
}


.openbtn{
  background-color: var(--rightsidevalikko-tausta);
}

.rightvalikko{
  background-color: var(--rightsidevalikko-tausta);
  color: green
}