.ohjepage {
  position: absolute;
  top: 60px;
  width: 100%;
  bottom: 0;
}

.ohjepage-iframe {
  width: 100%;
  border: none;
  height: 100%;
}
