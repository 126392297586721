.texti{
  position: fixed;
  margin-left: 65px;
  margin-top: 19px;
  color: #101010;
  font-size: 20px;
  font-weight: 700;
  /* font-style: italic; */
  z-index: 1000;
  opacity: 0.9;
}

/* .containeri {
  position: absolute;
  z-index: 1000;
} */
