@import url('../../configs/main.css');

.alavalikko::-webkit-scrollbar {
  display: none;
}

.alavalikko{
  z-index: 0;
  scrollbar-width: 0;
  background-color: var(--tapahtumat-alavalikko-tausta);
}
/* .testi {
  bottom: "55px";
} */
.textcontainer::-webkit-scrollbar {
  display: none;
}

.alavalikkocarddata {
  z-index: 0;
  scrollbar-width: 0;
}
.alavalikkocarddata::-webkit-scrollbar {
  z-index: 0;
  scrollbar-width: 0;
}

.carddate{
  background-color: var(--tapahtumatcard-date-tausta);
}

.alavalikkocard {
  background-color: var(--tapahtumatcard-tausta);
}

.cardimage{
  object-fit: cover;
  width: 100%;
  height: 70px;
}
